import React from "react";
import TaskInput from "./taskInput";
import TaskOutput from "./taskOutput";

function index() {
  return (
    <>
      <TaskInput />
      <TaskOutput />
    </>
  );
}

export default index;
